<template>
  <!-- 订单列表 -->
  <Layout>
    <div class="main-order">
      <div class="base-width">
        <!-- 面包屑 -->
        <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
        <div class="c-title-2 s22">我的订单</div>
        <!-- 搜索 -->
        <div class="md-order-search c-card">
          <form @submit.prevent="handleSubmit()" @reset.prevent="handleReset()">
            <!-- 订单状态 -->
            <div class="status flex s16 color-2 font-medium">
              <div class="status-item pointer"
                v-for="(item, index) in statusList"
                :key="item.status"
                :class="{ on: index == tab_index }"
                @click="setTab(index,item.status)"
              >
                {{ item.name }}
                <span class="num font-medium" v-if="item.count">{{item.count | numMax}}</span>
              </div>
            </div>
            <!-- 模糊搜索 -->
            <div class="form-item s16 color-4 flex-middle mb20">
              <span class="label">模糊搜索</span>
              <div class="cnt">
                <input
                  type="text" 
                  class="ipt" 
                  placeholder="请输入商品标题/订单号/买家姓名等关键字信息"
                  v-model="keyword"
                >
              </div>
            </div>
            <!-- 交易时间 -->
            <div class="flex-middle row-2">
              <div class="form-item s16 color-4 flex-middle form-item--date">
                <span class="label">交易时间</span>
                <div class="cnt">
                  <el-date-picker
                    v-model="time_range"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </div>
              </div>
              <!-- <div class="form-item s16 color-4 flex-middle form-item--name" v-show="showName">
                <span class="label">商品名称</span>
                <div class="cnt">
                  <input
                    type="text" 
                    class="ipt" 
                    placeholder="请输入商品名称"
                    v-model="name"
                  >
                </div>
              </div> -->
              <BaseButton size="large" tag='button' type='submit'>筛选</BaseButton>
              <BaseButton size="large" tag='button' type='reset' color='gray'>重置</BaseButton>
            </div>
          </form>
        </div>
        <!-- 订单列表 -->
        <div class="table">
          <!-- 表头 -->
          <div class="thead flex-middle s16 color-4">
            <div class="td td-1">商品信息</div>
            <div class="td td-2">订单状态</div>
            <div class="td td-3">
              <div class="flex-middle inline-flex sort" @click="handleFilter('goodsPrice')">
                <span :class="{ 'color-blue': sort_field == 'goodsPrice' }">商品金额</span>
                <span class="icon-sort bg-contain"
                  :class="{
                    'icon-sort-up': sort_field == 'goodsPrice' && sort_rule == 'asc',
                    'icon-sort-down': sort_field == 'goodsPrice' && sort_rule == 'desc',
                  }"
                ></span>
              </div>
            </div>
            <!-- <div class="td td-4">
              <div class="flex-middle inline-flex sort" @click="handleFilter('payPrice')">
                <span :class="{ 'color-blue': sort_field == 'payPrice' }">实付金额</span>
                <span class="icon-sort bg-contain"
                  :class="{
                    'icon-sort-up': sort_field == 'payPrice' && sort_rule == 'asc',
                    'icon-sort-down': sort_field == 'payPrice' && sort_rule == 'desc',
                  }"
                ></span>
              </div>
            </div> -->
            <div class="td td-5">收货信息</div>
            <div class="td td-6">操作</div>
          </div>
          <!-- 列表 -->
          <div class="order-list s16 color-4">
            <template v-for="(item, i) in orderList">
              <div class="order-li c-card" :key="i">
                <div class="order-hd flex-middle color-9">
                  <!-- <BaseButton v-if="item.biao" class="mr30 tag-biao" size="mini" style-type='border' :hover='false'>
                    投标
                  </BaseButton> -->
                  <div class="mr30">下单时间：{{ item.add_time }}</div>
                  <div class="mr30">订单号：{{ item.order_sn }}</div>
                  <!-- 店铺 -->
                  <router-link :to="`/shop/seller?suppliers_id=${item.suppliers_id}`" class="mr30 store flex-middle">
                    <BaseButton size='mini' color='green' :hover='false' style-type='light' class="tag-store tag-store-1">
                      {{ item.shop_type_desc }}
                    </BaseButton>
                    <!-- <BaseButton size='mini' color='blue' :hover='false' style-type='light' class="tag-store tag-store-2">
                      {{ item.shop_type_desc }}
                    </BaseButton> -->
                    <div class="">{{ item.company_name }}</div>
                  </router-link>
                  <!-- 支付类型 -->
                  <div class="mr30 color-4" v-if="item.pay_type == 2">{{ item.pay_type_text }}</div>
                  <div class="mr30 color-4" v-else>全款</div>
                  <!-- 右侧 -->
                  <div class="rg"></div>
                  <!-- 待付款 -->
                  <!-- <div v-if="item.pay_status == 0" class="ml30 color-red">剩余 {{ item.leftTime }} 内完成支付</div> -->
                  <!-- 其他提示 -->
                  <div v-if="item.pay_status == 1 && item.pay_type == 2 && item.period && item.period.money > 0" class="ml30 color-red">账期金额剩余: ￥{{ item.period.money }}</div>
                  <!-- <div class="ml30 color-red">请在14天59分59秒内处理发货</div> -->
                  <!-- 删除 -->
                  <img src="@/assets/images/icon-delete-2.png" alt="删除" class="btn-delete" @click="toDeleteOrder(item)">
                </div>
                <div class="order-msg flex">
                  <div class="td td-1">
                    <div class="goodsLength">{{ `共${item.goods_list.length}款商品` }}</div>
                    <!-- 商品 -->
                    <router-link :to="`/product-detail?goods_id=${item.goods_list[0].goods_id}`" class="goods flex-top">
                      <img :src="item.goods_list[0].goods_image" alt="" class="goods-cover">
                      <div class="goods-text s16 color-9">
                        <div class="t">
                          <span class="t_info">商品标题：</span>
                          <el-tooltip effect="dark" :content="item.goods_list[0].goods_name" placement="top-start">
                            <span class="color-4 t_value text-overflow">{{ item.goods_list[0].goods_name }}</span>
                          </el-tooltip>
                        </div>
                        <div class="t">
                          <span class="t_info">规格：</span>
                          <el-tooltip effect="dark" :content="item.goods_list[0].spec_key_name" placement="top-start">
                            <span class="color-4 t_value text-overflow">{{ item.goods_list[0].spec_key_name }}</span>
                          </el-tooltip>
                        </div>
                        <div class="t">
                          <span class="t_info">数量：</span><span class="color-4 t_value">{{ item.goods_list[0].goods_num }}</span>
                        </div>
                        <div class="flex-wrap s12 tags">
                          <div class="tag tag-1" v-for="(tagItem, tagIndex) in item.goods_list[0].tags" :key="tagIndex">{{ tagItem }}</div>
                          <!-- 配送方式 -->
                          <!-- <div class="tag tag-1" v-if="item.shipping == 1">货拉拉</div>
                          <div class="tag color-red" v-else-if="item.shipping == 2">自送</div>
                          <div class="tag tag-1" v-else>其他</div> -->
                          <!-- 支付类型 -->
                          <!-- <div class="tag tag-4" v-if="item.payType == 1">全款</div>
                          <div class="tag tag-2" v-else>账期45天</div> -->
                          <!-- 官方 -->
                          <!-- <div class="tag color-blue" v-if="item.store.official">官方</div>
                          <div class="tag tag-3" v-else>加盟商</div> -->
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- 订单状态 -->
                  <div class="td td-2">
                    <div>
                      <div v-html="item.order_status_text"></div>
                      <!-- <BaseTooltip class="reject-tips" v-if="item.reject">
                        <div slot='target' class="t color-blue">拒绝理由</div>
                        <div class="s14 color-9">
                          <p>时间: {{item.reject.time}}</p>
                          <p>拒绝理由: {{item.reject.reason}}</p>
                        </div>
                      </BaseTooltip> -->
                    </div>
                  </div>
                  <!-- 商品金额 -->
                  <div class="td td-3">￥{{ item.goods_list[0].goods_price }}</div>
                  <!-- 实付金额 -->
                  <!-- <div class="td td-4">
                    <div>
                      <div>1000.00元</div>
                      <div class="s14 color-red">剩余50分50秒内全部回款</div>
                    </div>
                  </div> -->
                  <!-- 收货信息 -->
                  <div class="td td-5">
                    <div>
                      <p>收货人: {{ item.consignee }} {{ item.mobile }}</p>
                      <p>地址信息: {{ item.address }}</p>
                      <div v-if="item.shipping_btn == 1" class="btn-logistics color-blue font-medium">
                        <router-link :to="`/order-detail?order_id=${item.order_id}`">物流信息</router-link>
                      </div>
                    </div>
                  </div>
                  <!-- 操作 -->
                  <div class="td td-6 font-medium">
                    <div>
                      <template>
                        <!-- 立即付款 -->
                        <div v-if="item.pay_btn == 1" class="btn-handle" @click="toPay(item)">
                          <BaseButton color='red' class="btn-pay">立即支付</BaseButton>
                        </div>
                        <!-- 重新购买 -->
                        <div class="btn-handle" v-if="item.pay_status == 1">
                          <BaseButton color='red' class="btn-pay" @click.native="buyAgain(item)">重新购买</BaseButton>
                        </div>
                        <!-- 详情 -->
                        <div class="btn-do" @click="toOrderDetail(item.order_id)">
                          订单详情
                        </div>
                        <!-- 催发货 -->
                        <div v-if="item.remind_btn == 1" class="btn-do pointer color-blue" @click="toUrgeGoods(item)">催发货</div>
                        <!-- 取消订单 -->
                        <div v-if="item.cancel_btn == 1" class="btn-do pointer color-red" @click="toCancel(item)">取消订单</div>
                        <!-- 确认收货 -->
                        <div v-if="item.receive_btn == 1" class="btn-do pointer color-red" @click="toOrderReceive(item)">确认收货</div>
                        <!-- 去评价 -->
                        <div v-if="item.comment_btn == 1" class="btn-do pointer color-red" @click="toEvaluate(item)">去评价</div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- 分页 -->
        <Pagination
        :key="pagKey"
          :page="pager.p"
          :size="pager.page_num"
          :total="total"
          v-ani.fade-up
          @change="handlePager"
        />
      </div>
    </div>
    <!-- 弹窗 -->
    <!-- 删掉订单 -->
    <DialogOrderDelete
      v-if='dialog_delete'
      @cancel='dialog_delete = false'
      @confirm='deleteOrderConfirm'
    />
    <!-- 取消订单 -->
    <DialogOrderCancel
      v-if='dialog_cancel'
      @close='dialog_cancel = false'
      @cancel='dialog_cancel = false'
      @confirm='cancelConfirm'
      :orderId="currentOrder.order_id"  
    />
    <!-- 确认收货 -->
    <DialogOrderConfirm
      v-if='dialog_confirm'
      :orderId="currentOrder.order_id" 
      @close='dialog_confirm = false'
      @cancel='dialog_confirm = false'
      @confirm='receiveOrderConfirm'
    />
    <!-- 评价 -->
    <DialogOrderEvaluate
      :orderId="currentOrder.order_id" 
      v-if='dialog_evaluate'
      @close='dialog_evaluate = false'
      @cancel='dialog_evaluate = false'
      @confirm='elevateConfirm'
    />
    <!-- 买家退货发货 -->
    <DialogOrderSendGoods
      v-if="dialog_send_goods"
      @close='dialog_send_goods = false'
      @cancel='dialog_send_goods = false'
      @confirm='dialog_send_goods = false'
    />
    <!-- 发货信息 -->
    <DialogOrderSendGoodsMsg
      v-if="dialog_send_goods_msg"
      @close='dialog_send_goods_msg = false'
      @cancel='dialog_send_goods_msg = false'
      @confirm='dialog_send_goods_msg = false'
    />
    <!-- 商品反馈 -->
    <DialogOrderProdFeedback
      v-if="dialog_prod_feedback"
      @close='dialog_prod_feedback = false'
      @cancel='dialog_prod_feedback = false'
      @confirm='dialog_prod_feedback = false'
    />
    <!-- 催发货 -->
    <DialogSuccess
      title="催发成功"
      text="供应商将尽快处理发货"
      v-if="dialog_urge_goods"
      @close='dialog_urge_goods = false'
      @confirm='dialog_urge_goods = false'
    />
  </Layout>
</template>

<script>
import { OrderListApi, OrderDataCountApi, RemindSendApi, receiveOrderApi, deleteOrderApi, buyAgainApi } from "@/request/api/order.js"
export default {
  data() {
    return {
      statusList: [
        { status: 0, name: '全部', count: 0, payType: 1 },
        { status: 2, name: '待审核', count: 0, payType: 2, periodState: 1 },
        { status: 6, name: '账期订单', count: 0, payType: 2 },
        { status: 1, name: '待付款', count: 0, payType: 1 },
        { status: 7, name: '待发货', count: 0, payType: 1 },
        { status: 3, name: '待收货', count: 0, payType: 1 },
        { status: 4, name: '已完成', count: 0, payType: 1 },
        { status: 8, name: '已关闭', count: 0, payType: 1 },
        { status: 5, name: '售后中', count: 0, payType: 1 },
      ],
      pagKey: 0,
      tab_index: 0,
      keyword: '',
      time_range: [],
      currentStatus: 0,
      pager: {
        page_num: 10,
        p: 1
      },
      total: 0,
      timer: "",
      routes: [
        { name: '首页', link: '/'},
        { name: '集采商城', link: '/shop'},
        { name: '全部订单', link: ''},
      ],
      sort_field: "auto",
      sort_rule: "desc",
      operateList: [
        { name: 'pay', text: '立即支付'},
        { name: 'rebuy', text: '重新购买'},
        { name: 'detail', text: '订单详情' },
        { name: 'urge_send_goods', text: '催发货' },
        { name: 'cancel', text: '取消订单' },
        { name: 'confirm', text: '确认收货' },
        { name: 'evaluate', text: '去评价' },
        { name: 'kefu', text: '客服介入' },
        { name: 'goship', text: '去发货' },
        { name: 'revoke_after_sales', text: '撤销售后' },
        { name: 'urge_get_goods', text: '催收货' },
      ],
      orderList: [],
      currentOrder: null,
      // list: [
      //   {
      //     biao: true,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: true },
      //     status: 3, // 未付款
      //     status_text: '已下单，未付款',
      //     shipping: 1, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: false,
      //     goods: {},
      //     operate: [
      //       { name: 'pay', text: '立即支付'},
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'cancel', text: '取消订单' }
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 7, // 已关闭
      //     status_text: '下单未付款<br>订单已关闭',
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: false,
      //     goods: {},
      //     operate: [
      //       { name: 'rebuy', text: '重新购买'},
      //       { name: 'detail', text: '订单详情' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 4, // 待发货
      //     status_text: '已付款，待发货',
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: false,
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'urge_send_goods', text: '催发货' },
      //       { name: 'cancel', text: '取消订单' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 5, // 已发货
      //     status_text: '部分已发货<br>待收货',
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: false, // 没有全部发货
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'urge_send_goods', text: '催发货' },
      //       { name: 'cancel', text: '取消订单' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 5, // 已发货
      //     status_text: '已发货，待收货',
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: true, // 全部发货
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'confirm', text: '确认收货' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 6, // 已完成
      //     status_text: '订单完成',
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: true, // 全部发货
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'confirm', text: '确认收货' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 6, // 已完成
      //     status_text: '订单完成，待评价',
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: true, // 全部发货
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'evaluate', text: '去评价' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 2, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 1, // 待审核
      //     status_text: '待审核',
      //     periodState: 1,
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: false, // 全部发货
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'cancel', text: '取消订单' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 2, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 5, // 待审核
      //     status_text: '审核通过，待收货',
      //     periodState: 2,
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: true, // 全部发货
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'confirm', text: '确认收货' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 2, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 1, // 待审核
      //     status_text: '审核失败',
      //     periodState: 3,
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: false, // 全部发货
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'cancel', text: '取消订单' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 8, // 售后中
      //     status_text: '发起售后<br>待卖家处理',
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: false, // 全部发货
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'confirm', text: '确认收货' },
      //       { name: 'kefu', text: '客服介入' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 8, // 售后中
      //     status_text: '卖家同意换货<br>待买家发货',
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: false, // 全部发货
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'goship', text: '去发货' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 8, // 售后中
      //     status_text: '卖家拒绝换货<br>待买家处理',
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: false, // 全部发货
      //     goods: {},
      //     reject: {
      //       time: '2023/01/01 14:00',
      //       reason: '定制产品，不支持换货'
      //     },
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'cancel', text: '取消订单' },
      //       { name: 'revoke_after_sales', text: '撤销售后' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 8, // 售后中
      //     status_text: '买家已发货<br>待卖家收货',
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: false, // 全部发货
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'urge_get_goods', text: '催收货' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 8, // 售后中
      //     status_text: '卖家已收货<br>待卖家发货',
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: false, // 全部发货
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //       { name: 'urge_send_goods', text: '催发货' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 8, // 售后中
      //     status_text: '售后关闭',
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: false, // 全部发货
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //     ]
      //   }, {
      //     biao: false,
      //     payType: 1, // 支付类型：1-全款，2-账期
      //     store: { official: false },
      //     status: 8, // 售后中
      //     status_text: '售后完成',
      //     shipping: 2, // 配送方式：1-货拉拉，2-自送，3-其他物流
      //     shipping_all: false, // 全部发货
      //     goods: {},
      //     operate: [
      //       { name: 'detail', text: '订单详情' },
      //     ]
      //   }
      // ],
      dialog_delete: false,
      dialog_cancel: false,
      dialog_confirm: false,
      dialog_evaluate: false,
      dialog_complaint: false,
      dialog_send_goods: false,
      dialog_send_goods_msg: false,
      dialog_prod_feedback: false,
      dialog_urge_goods: false
    };
  },
  mounted(){
    this.orderDataCount();
    this.handleSubmit();
    // this.timer = setInterval(() => {
    //   this.orderList = this.orderList.map((item) => {
    //     item.pay_expire_desc = "2024-01-19 10:00:00";
    //     item.leftTime = this.dealTimeExpire(item.pay_expire_desc);
    //     return item;
    //   });
    // },10000)
  },
  watch: {
    currentStatus(){
      this.handleSubmit();
    }
  },
  created() {
    this.tab_index = this.$route.query.tab || 0
    this.currentStatus = this.$route.query.status || 0
  },
  methods: {
    //数量统计
    orderDataCount(){
      OrderDataCountApi().then(res => {
        if(res.status == 200){
          this.statusList = this.statusList.map(item => {
            if(item.status == 1){
              item.count = res.data.to_be_paid;
            }else if(item.status == 2){
              item.count = res.data.to_be_reviewed;
            }else if(item.status == 3){
              item.count = res.data.to_be_reviewed;
            }else if(item.status == 4){
              item.count = res.data.to_be_success;
            }else if(item.status == 5){
              item.count = res.data.to_be_sales;
            }else if(item.status == 6){
              item.count = res.data.period_count;
            }else if(item.status == 7){
              item.count = res.data.to_be_send;
            }else if(item.status == 8){
              item.count = res.data.to_be_cancel;
            }
            return item;
          })
        }
      })
    },
    // 分页
    handlePager(page) {
      this.pager.p = page;
      this.handleSubmit();
    },
    //切换tab
    setTab(val,status) {
      this.tab_index = val;
      this.currentStatus = status;
      this.handleSubmit();
    },
    //重置
    handleReset(){
      this.keyword = "";
      this.time_range = [];
    },
    //获取列表
    async handleSubmit() {
      let params = {
        keyword: this.keyword,
        type: this.currentStatus,
        time_range: this.time_range && this.time_range.length > 0 ? this.time_range.join('~') : "",
        page_num: 10,
        p: this.pager.p
      }
      await OrderListApi(params).then(res => {
        if(res.status == 200){
          this.orderList = res.data.list;
          this.total = res.data.total;
          this.pagKey++;
          this.orderList = this.orderList.map((item,index) => {
            this.$set(this.orderList, index, "leftTime", "")
            return item;
          })
        }
      })
    },
    formatBit (val) {
      val = +val
      return val > 9 ? val : '0' + val
    },
    //处理剩余时间-未付款
    dealTimeExpire(time){
      let deadLineTime = new Date(time).getTime() 
      let timeNow = new Date().getTime();
      let leftTime = deadLineTime - timeNow;

      const minutes = this.formatBit(Math.floor(leftTime % 3600 / 60));
      const remainingSeconds = this.formatBit(leftTime %  60);
      return `${minutes}分钟 ${remainingSeconds}秒`;
    },

    handleFilter(name) {
      this.sort_field = name
      this.sort_rule = this.sort_rule == 'asc' ? 'desc' : 'asc'
    },

    //获取详情
    toOrderDetail(orderId){
      this.$router.push({
        path: "/order-detail",
        query: {
          order_id: orderId
        }
      })
    },
    //跳转立即付款
    toPay(item){
      this.$router.push({
        path: "/pay",
        query: {
          order_id: item.order_id,
          merge_order_sn: item.merge_order_sn
        }
      })
    },
    //催发货
    toUrgeGoods(item){
      this.currentOrder = item;
      RemindSendApi({order_id: this.currentOrder.order_id}).then(res => {
        if(res.status == 200){
          this.dialog_urge_goods = true;
          this.handleSubmit();
          this.orderDataCount();
        }
      })
    },
    //取消订单
    toCancel(item){
      this.currentOrder = item;
      this.dialog_cancel = true;
    },
    cancelConfirm(){
      this.dialog_cancel = false;
      this.handleSubmit();
      this.orderDataCount();
    },
    //确认收货
    toOrderReceive(item){
      this.currentOrder = item;
      this.dialog_confirm = true;
    },
    receiveOrderConfirm(){
      receiveOrderApi({order_id: this.currentOrder.order_id}).then(res => {
        if(res.status == 200){
          this.dialog_confirm = false;
          this.$message.success("已确认收货");
          this.handleSubmit();
          this.orderDataCount();
        }
      })
    },
    //删除订单
    toDeleteOrder(item){
      this.currentOrder = item;
      this.dialog_delete = true;
    },
    deleteOrderConfirm(){
      deleteOrderApi({order_id: this.currentOrder.order_id}).then(res => {
        if(res.status == 200){
          this.dialog_delete = false;
          this.$message.success("删除订单成功");
          this.handleSubmit();
          this.orderDataCount();
        }
      })
    },
    //去评价
    toEvaluate(item){
      this.currentOrder = item;
      this.dialog_evaluate = true;
    },
    elevateConfirm(){
      this.dialog_evaluate = false;
      this.handleSubmit();
      this.orderDataCount();
    },
    //判断是否可以再次购买
    buyAgain(item){
      buyAgainApi({order_id: item.order_id}).then(res => {
        if(res.status == 200){
          this.$router.push(`/shop-cart`)
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import './list.scss';
</style>